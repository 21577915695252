import request from "tools/request.js"

//商品设置 获取
class shangpin_add_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 // let m_shpi_id 		 	 = that.parent.m_shpi_id;
		  let session = localStorage.getItem("session");
		 let params = { 
			session : session
		 } 
		 
		request.post('/shangpin/shangpin_add_get',params).then(res => { 
			  
			that.parent.m_fenlei_list_1 = res.data.fenlei_1;
			that.parent.shpi_fenlei_1 = res.data.fenlei_1[0]["fele_id"];
			that.parent.m_fenlei_list_2 = res.data.fenlei_2;
			that.parent.shpi_fenlei_2 = res.data.fenlei_2[0]["fele_id"];
			that.parent.m_fenlei_list_3 = res.data.fenlei_3;
			that.parent.shpi_fenlei_3 = res.data.fenlei_3[0]["fele_id"];
			that.parent.m_fenlei_list_4 = res.data.fenlei_4;
			that.parent.shpi_fenlei_4 = res.data.fenlei_4[0]["fele_id"];
			
			that.parent.m_pinpai_list = res.data.pinpai; 
			that.parent.shpi_pinpai_id = res.data.pinpai[0]["pipa_id"];
			console.log(that.parent.m_shangpin_info);
		})
		 
	}
}

export default shangpin_add_get