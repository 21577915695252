import request from "tools/request.js"

//建筑分类 添加建筑分类
class jianzhu_fenlei_add_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		   
		let session 					= localStorage.getItem("session"); 
		 
	 
 
		let params = { 
			 
			shpi_pinpai_id : that.parent.shpi_pinpai_id,
			shpi_xinghao : that.parent.shpi_xinghao,
			shpi_jianjie : that.parent.shpi_jianjie,
			shpi_jieshao : that.parent.shpi_jieshao,
			shpi_label_2 : that.parent.shpi_label_2,
			shpi_pingfen : that.parent.shpi_pingfen,
			shpi_dianzan : that.parent.shpi_dianzan,
			shpi_pinglun : that.parent.shpi_pinglun,
			shpi_paixu : that.parent.shpi_paixu,
			shpi_fenlei_1 : that.parent.shpi_fenlei_1, 
			shpi_fenlei_2 : that.parent.shpi_fenlei_2, 
			shpi_fenlei_3 : that.parent.shpi_fenlei_3, 
			shpi_fenlei_4 : that.parent.shpi_fenlei_4, 
			m_img2 : that.parent.m_img2,  
			m_img_1 : that.parent.m_img2_1,
			m_img_2 : that.parent.m_img2_2,
			m_img_3 : that.parent.m_img2_3,
			m_img_4 : that.parent.m_img2_4,
			m_img_5 : that.parent.m_img2_5, 
			shpi_danwei : that.parent.shpi_danwei,
			shpi_xiaoliang : that.parent.shpi_xiaoliang,
			shpi_jiage : that.parent.shpi_jiage,
			session : session
		} 
		
		request.post('/shangpin/shangpin_add_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_1 = false;
			that.parent.parent.shangpin_get.m_main(); 
		})
		 
	}
}

export default jianzhu_fenlei_add_post