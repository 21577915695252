import request from "tools/request.js"

//商品设置 获取
class shangpin_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_shpi_id 		 	 = that.parent.m_shpi_id;
		  let session = localStorage.getItem("session");
		 let params = {
		 	m_shpi_id : m_shpi_id,
			session : session
		 } 
		 
		request.post('/shangpin/shangpin_info_get',params).then(res => { 
			  
			  that.parent.m_fenlei_list_1 = res.data.fenlei_1; 
			  that.parent.m_fenlei_list_2 = res.data.fenlei_2; 
			  that.parent.m_fenlei_list_3 = res.data.fenlei_3; 
			  that.parent.m_fenlei_list_4 = res.data.fenlei_4;
			  
			  // that.parent.shpi_fenlei_1 = res.data.fenlei[0]["fele_id"];
			  that.parent.m_pinpai_list = res.data.pinpai; 
			  // that.parent.shpi_pinpai_id = res.data.pinpai[0]["pipa_id"];
			  
			that.parent.m_shangpin_info = res.data.m_shangpin_info; 
			  
			that.parent.m_img_1_1 = that.parent.m_shangpin_info["shpi_image_0"];
			that.parent.m_img_2_1 = that.parent.m_shangpin_info["shpi_image_1"];
			that.parent.m_img_3_1 = that.parent.m_shangpin_info["shpi_image_2"];
			that.parent.m_img_4_1 = that.parent.m_shangpin_info["shpi_image_3"];
			that.parent.m_img_5_1 = that.parent.m_shangpin_info["shpi_image_4"]; 
			that.parent.m_img_6_1 = that.parent.m_shangpin_info["shpi_image_5"]; 
			
			that.parent.m_img_1_2 = that.parent.m_shangpin_info["shpi_image_00"];
			that.parent.m_img_2_2 = that.parent.m_shangpin_info["shpi_image_11"];
			that.parent.m_img_3_2 = that.parent.m_shangpin_info["shpi_image_22"];
			that.parent.m_img_4_2 = that.parent.m_shangpin_info["shpi_image_33"];
			that.parent.m_img_5_2 = that.parent.m_shangpin_info["shpi_image_44"]; 
			that.parent.m_img_6_2 = that.parent.m_shangpin_info["shpi_image_55"]; 
			console.log(that.parent.m_shangpin_info);
		})
		 
	}
}

export default shangpin_set_get