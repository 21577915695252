<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">商品名称:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_shpi_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col> 
				<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加商品
					</el-button>
				</el-col>
			</el-row> 
			<br />


			<br />
			<el-table :data="m_shangpin_list" style="width: 100%">
				<el-table-column prop="shpi_id" label="id">
				</el-table-column>
				<el-table-column prop="pipa_name" label="品牌">
				</el-table-column>  
				<el-table-column prop="shpi_xinghao" label="型号">
				</el-table-column> 
				<el-table-column prop="shpi_jianjie" label="简介">
				</el-table-column>
				 
				<el-table-column label="操作" show-overflow-tooltip align="right">
					<template slot-scope="scope">
						 <el-button size="mini" type="danger" @click="f_shangpin_set(scope.row.shpi_id )">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<br />
			<br />
			
			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="添加商品" :visible.sync="drawer_1" size="600px">
			<shangpin_add :parent="me"></shangpin_add>
		</el-drawer>

		<el-drawer title="修改商品" :visible.sync="drawer_2" size="600px">
			<shangpin_set :key="m_shpi_id" :m_shpi_id="m_shpi_id" :parent="me"></shangpin_set>
		</el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import shangpin_add from './components/shangpin_add/shangpin_add'
	import shangpin_set from './components/shangpin_set/shangpin_set'
	import shangpin_get from "./expand/shangpin_get.js"


	export default {
		components: {
			layout,
			shangpin_add,
			shangpin_set
		},
		data() {
			return {
				me: this,
				m_shpi_name: "",
				m_shangpin_list: [],
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_shpi_id : 0,
				m_shpi_shan_id : ""
			}
		},
		created() {
			const that = this;
			if(this.$route.query.shangjia_id >0){
				that.m_shpi_shan_id = this.$route.query.shangjia_id;
			}
			  
			that.shangpin_get = new shangpin_get(that);
			that.shangpin_get.m_main(); 
			 
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}, 
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.shangpin_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				
				that.shangpin_get.m_main();
			},
			f_shangpin_set(shpi_id) {
				const that = this;
				
				that.m_shpi_id = shpi_id;
				that.drawer_2 = true; 
			}
		}
	}
</script>
