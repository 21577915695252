import request from "tools/request.js"

//商品设置 提交
class shangpin_set_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
		let m_shangpin_info 	= that.parent.m_shangpin_info; 
		let m_img_1_1 			= that.parent.m_img_1_2;
		let m_img_2_1 			= that.parent.m_img_2_2;
		let m_img_3_1 			= that.parent.m_img_3_2;
		let m_img_4_1 			= that.parent.m_img_4_2;
		let m_img_5_1 			= that.parent.m_img_5_2;
		let m_img_6_1 			= that.parent.m_img_6_2; 
		let m_shpi_id 			= that.parent.m_shpi_id;
		let session 		    = localStorage.getItem("session"); 
		  
		let params = { 
			m_shangpin_info : m_shangpin_info,
			m_img_1_1 : m_img_1_1,
			m_img_2_1 : m_img_2_1,
			m_img_3_1 : m_img_3_1,
			m_img_4_1 : m_img_4_1,
			m_img_5_1 : m_img_5_1,
			m_img_6_1 : m_img_6_1,
			m_shpi_id : m_shpi_id,
			session : session
		} 
		
		request.post('/shangpin/shangpin_info_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.shangpin_get.m_main(); 
		})
		 
	}
}

export default shangpin_set_post